import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import { Tabs, Tab, TabPanel, TabList, TabProvider } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import Cards from "../../components/chat-channel/Cards";
import useInterval from "../../react/useInterval";
import { useSwipeable, Swipeable } from "react-swipeable";

const servicenow = require("../../assets/images/icons-web/servicenow.svg");
const freshservice = require("../../assets/images/icons-web/freshservice.svg");
const jira = require("../../assets/images/icons-web/jira.svg");
const solarwinds = require("../../assets/images/icons-web/solarwinds.svg");
const service_desk = require("../../assets/images/icons-web/service_desk.svg");
const jumpcloud = require("../../assets/images/icons-web/jumpcloud.svg");
const ad = require("../../assets/images/icons-web/zure.svg");
const office365 = require("../../assets/images/icons-web/ms_office.svg");
const okta = require("../../assets/images/icons-web/okta.svg");
const onelogin = require("../../assets/images/icons-web/onelogin.svg");
const bamboo = require("../../assets/images/icons-web/bamboo.svg");
const orangehrm = require("../../assets/images/icons-web/orangehrm.svg");
const zoho_people = require("../../assets/images/icons-web/zoho_people_1.svg");
const gusto = require("../../assets/images/icons-web/gusto.svg");
const sap = require("../../assets/images/icons-web/sap_successfactors.svg");
const intune = require("../../assets/images/icons-web/ms_intune.svg");
const mobileiron = require("../../assets/images/icons-web/mobile_iron.svg");
const hexnode = require("../../assets/images/icons-web/hexnode.svg");
const citrix = require("../../assets/images/icons-web/citrix.svg");

const mobile = require("../../assets/images/landing_page/customers_mob.png");

const topImage = require("../../assets/automate/ibm/ibm_watson_header.png");

const section_four = require("../../assets/automate/ibm/ibm_watson_sec_2.png");
const section_five = require("../../assets/automate/ibm/auto_chatbot_sec_3.png");

const noCoding = require("../../assets/images/landing_page/bottom_icon_1.png");
const supportAutomation = require("../../assets/images/landing_page/bottom_icon_2.png");
const boostIT = require("../../assets/images/landing_page/bottom_icon_3.png");

const featureContent = [
  {
    type: "left",
    header:
      "Use pre-built workflows for your chatbot",
    content:
      "Workativ comes with over 400+ pre-built workflow automations for popular apps like ITSM, HR, Access Management, MDM, Knowledge Management and so on, that are ready to use from our workflow marketplace. No coding.",
    content1: " Just setup and go live instantly.",
    link: null,
    image: section_four,
    bgColor: "bg_landing_grey",
    alt: "pre built workflows for ibm watson chatbot",
  },
  {
    type: "right",
    header: "Integrate your chatbot with popular apps",
    content:
      "Get more out of IBM Watson with one-click app integrations that’s intuitive and enables you to go-live in record time, with zero coding.  ",
    content1:
      "Yeah, you heard that right, not a single line of code.",
    link: null,
    image: section_five,
    bgColor: "bg_landing_white",
    alt: "integrate ibm watson chatbot",
  },
];

const cardData = [
  {
    header: "Encryption",
    content:
      "AES 256 bit encryption with 1,024-bit key-strength for data at Rest and TLS encryption for data in transit.",
    image: noCoding,
  },
  {
    header: "Access Controls",
    content:
      "Workativ implements role-based access through IAM that enforces segregation of duties, two-factor authentication, and end-to-end audit trails, ensuring access is in accordance with security policy.",
    image: supportAutomation,
  },
  {
    header: "Connect On-prem Apps",
    content: `Connect your chatbot with your on-prem apps using our <span><a href='/conversational-ai-platform/on-prem-app-connector'>secure connector</a></span> so you can create workflows to automate tasks from chatbot without a hitch, be it on-prem or cloud.`,
    image: boostIT,
  },
];

const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);
const headerMenu = [
  {
    menu: "IT Helpdesk Chatbot",
    href: "#chatbot",
    id: "chatbot",
    offset: null,
  },
  {
    menu: "IT Workflows",
    href: "#workflow",
    id: "workflow",
    offset: 450,
  },
  {
    menu: "Marketplace",
    href: "#marketplace",
    id: "marketplace",
    offset: 150,
  },
  {
    menu: "Slack & MS Teams",
    href: "#slack",
    id: "slack",
    offset: 150,
  },
  {
    menu: "App Integrations",
    href: "#integrations",
    id: "integrations",
    offset: 150,
  },
];

const OpenMenu = () => {
  const [isActive, setIsActive] = React.useState("HR Chatbot");

  const [showMenu, setShowMenu] = React.useState(false);
  const onClick = () => setShowMenu(!showMenu);
  return (
    <>
      <a
        className={
          !showMenu
            ? "navbar-brand js-scroll-trigger"
            : "navbar-brand js-scroll-trigger icon_open"
        }
        href="javascript:void(0);"
      >
        <img
          src={require("../../assets/images/workativ-logo.png")}
          alt="Workativ"
        />
      </a>
      <span>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span
            className={
              !showMenu
                ? "navbar-toggler-icon"
                : "navbar-toggler-icon icon_open"
            }
            onClick={onClick}
          >
            {!showMenu ? (
              <svg viewBox="0 -53 384 384">
                <path d="M368 154.668H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 32H16C7.168 32 0 24.832 0 16S7.168 0 16 0h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 277.332H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0"></path>
              </svg>
            ) : (
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 512.001 512.001"
                xmlSpace="preserve"
              >
                <path d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0014.143 5.857 19.94 19.94 0 0014.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z"></path>
              </svg>
            )}
          </span>
        </button>
      </span>
      {showMenu ? (
        <div id="results" className="search-results">
          <div className="navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              {headerMenu.map((menu) => (
                <li className="nav-item" onClick={onClick}>
                  <AnchorLink
                    offset={() => 160}
                    href={menu.href}
                    className={
                      isActive === menu.menu
                        ? "url_manipulation nav-link js-scroll-trigger active"
                        : "url_manipulation nav-link js-scroll-trigger "
                    }
                    onClick={() => {
                      setIsActive(menu.menu);
                    }}
                  >
                    {" "}
                    {menu.menu}
                  </AnchorLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
};

const Menu = () => (
  <div id="results" className="search-results">
    <div className="navbar-collapse" id="navbarResponsive">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#chatbot"
          >
            HR Chatbot
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#workflow"
          >
            HR Workflows
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#marketplace"
          >
            Marketplace
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#slack"
          >
            Slack & MS Teams
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#integrations"
          >
            App Integrations
          </AnchorLink>
        </li>
      </ul>
    </div>
  </div>
);

export default function FeatureHomePage() {
  const [isActive, setIsActive] = React.useState("HR Chatbot");

  const slides = [
    {
      image: unlock_account,
      title: "Unlock Account",
      tabFor: "vertical-tab-one",
      alt:"ibm watson unlock account automation"
    },
    {
      image: password_resets,
      title: "Password Reset",
      tabFor: "vertical-tab-two",
      alt:"ibm watson password reset automation"
    },
    {
      image: user_provisioning,
      title: "User Provisioning",
      tabFor: "vertical-tab-three",
      alt:"ibm watson user provisioning automation"
    },
    {
      image: access_management,
      title: "Access Management",
      tabFor: "vertical-tab-four",
      alt:"ibm watson access management automation"
    },
    {
      image: asset_requests,
      title: "Asset Requests",
      tabFor: "vertical-tab-five",
      alt:"ibm watson asset requests automation"
    },
    {
      image: onboarding,
      title: "Onboarding",
      tabFor: "vertical-tab-six",
      alt:"ibm watson onboarding automation"
    },
    {
      image: offboarding,
      title: "Offboarding",
      tabFor: "vertical-tab-seven",
      alt:"ibm watson offboarding automation"    
    },
  ];

  const slideWidth = 100;
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // console.log("decrementOffset")
      incrementOffset();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const total = slides.length; // the length of the slide array
  const enabled = true; // pauses/disables the player
  const useLoaded = false; // this allows for delayed loads like images or embeds
  const speed = 10000; // speed in milliseconds to show each slide
  const loop = true;

  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState([]);
  function incrementOffset() {
    if (offset === total - 1) {
      setOffset(loop ? 0 : offset);
    } else {
      // console.log("setOffset", offset)
      setOffset(offset + 1);
    }
  }

  function decrementOffset() {
    if (offset === 0) {
      setOffset(loop ? total - 1 : offset);
    } else {
      setOffset(offset - 1);
    }
  }

  const loaded = useLoaded ? items.length === total : true;
  useInterval(() => {
    if (loaded && enabled && offset < total) {
      incrementOffset();
    }
  }, speed);

  const [isOpen, setOpen] = React.useState(false);

  const [header, setHeader] = useState("top_menu_sticky");

  const [dropdown, setDropdown] = useState("HR Chatbot");

  const listenScrollEvent = (e) => {
    if (window.scrollY < 73) {
      return setHeader("top_menu_sticky");
    } else if (window.scrollY > 70) {
      return setHeader("top_menu_sticky top_menu_sticky_fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  // console.log("offset", offset)

  // console.log("FeatureHomePage", offset)

  return (
    <>
      <TitleAndMetaTags
        title="Free Workflow Automation for Chatbots | Workativ Automate"
        description="Integrate Chatbot with your applications easily to automate workflows and tasks easily in minutes. No coding required. "
        keywords={[
          "IBM Watson Chatbot",
          "workativ free trial",
          "helpdesk chatbot free trial",
          "automation",
        ]}
        ogImage={topImage}
            />
      <section className="it_support_chatbot  container_trial integrations_container landing_page_chatbot">
        <Container>
            <div className="automate_tabslider_wrapper automate_product">
          <Layout backgroundColor={"landing_bg"} logoFor="AUTOMATE" product="AUTOMATE">
            <RegisterSection
              rightImage={topImage}
              backgroundColor={"landing_bg"}
              altImage={
                "ibm watson workflow automation"
              }
              iD={"chatbot"}
            >
              <RegisterSection.FormWithContent product="automate" content="">
                <RegisterSection.MainHeader>
                Got Chatbot? <br></br>Now integrate apps and add workflow automation instantly.
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                Workativ automate enables you to add powerful workflow automations capabilities out-of-the box to your chatbots with zero coding.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>

            <TabProvider defaultTab="vertical-tab-one" vertical>
              <section
                className="features_img_left pl-10-tage landing_page trial_page_img bg_landing_white bg_landing_white_autoamte"
                id="workflow"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 feature_page_img_right mob_slider_pad_0">
                      <div className="desktop_tabs">
                        <TabList>
                          {slides.map((menu) => (
                            <Tab tabFor={menu.tabFor}>{menu.title}</Tab>
                          ))}
                        </TabList>
                        {slides.map((menu) => (
                          <TabPanel tabId={menu.tabFor}>
                            <img src={menu.image} alt={menu.title}></img>
                          </TabPanel>
                        ))}
                      </div>
                      <div className="slider_wrapper">
                        <div
                          className="slider_container"
                          id={slides[offset].tabFor}
                          {...handlers}
                        >
                          <img
                            src={slides[offset].image}
                            alt={slides[offset].title}
                          />
                        </div>
                        <div className="controls_wrapper">
                          <span
                            className="controls_left"
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              decrementOffset();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <g
                                id="left_arrow"
                                transform="translate(-20 -1622)"
                              >
                                <circle
                                  id="Ellipse_254"
                                  data-name="Ellipse 254"
                                  cx="12"
                                  cy="12"
                                  r="12"
                                  transform="translate(20 1622)"
                                  fill="#fff"
                                />
                                <g
                                  id="Group_6414"
                                  data-name="Group 6414"
                                  transform="translate(-396 -258)"
                                >
                                  <g
                                    id="Group_6413"
                                    data-name="Group 6413"
                                    transform="translate(424 1886)"
                                  >
                                    <path
                                      id="Path_3332"
                                      data-name="Path 3332"
                                      d="M430.629,1898.239a.508.508,0,0,1-.344-.134l-6.119-5.609a.51.51,0,0,1,0-.752l6.119-5.609a.51.51,0,0,1,.689.752l-5.709,5.234,5.709,5.233a.51.51,0,0,1-.345.886Z"
                                      transform="translate(-424 -1886)"
                                      fill="#2c7357"
                                    />
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </span>
                          <span>{slides[offset].title}</span>
                          <span
                            className="controls_right"
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              incrementOffset();
                            }}
                          >
                            <svg
                              id="right_arrow"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                id="Ellipse_254"
                                data-name="Ellipse 254"
                                cx="12"
                                cy="12"
                                r="12"
                                fill="#fff"
                              />
                              <g
                                id="Group_6414"
                                data-name="Group 6414"
                                transform="translate(8.861 6)"
                              >
                                <g id="Group_6413" data-name="Group 6413">
                                  <path
                                    id="Path_3332"
                                    data-name="Path 3332"
                                    d="M424.51,1898.239a.508.508,0,0,0,.344-.134l6.119-5.609a.51.51,0,0,0,0-.752l-6.119-5.609a.51.51,0,0,0-.689.752l5.709,5.234-5.709,5.233a.51.51,0,0,0,.345.886Z"
                                    transform="translate(-424 -1886)"
                                    fill="#2c7357"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 feature_page_content_left">
                      <h2>Chatbot + workflow automation = super chatbot</h2>
                      <p className="desktop_tab_list">
                      Add workflow automation to your chatbot instantly to streamline and automate workflows such as {" "}
                        {buttonMenu.slice(0, 5).map((menu, i) => (
                          <li className="button_list_li">
                            <span
                              className={isActive === menu.tabName ? "" : ""}
                              onClick={() => {
                                setIsActive(menu.tabName);
                                setOffset(i);
                              }}
                            >
                              {" "}
                              {menu.content && (
                                <span className="pad_content">
                                  {menu.content}
                                </span>
                              )}
                              {menu.tabFor1 ? (
                                <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                              ) : null}
                            </span>
                            <span className="space_left"> ,</span>
                          </li>
                        ))}
                        and employee processes like{" "}
                        {buttonMenu.slice(5, 7).map((menu, i) => (
                          <li className="button_list_li">
                            <span
                              className={isActive === menu.tabName ? "" : ""}
                              onClick={() => {
                                setIsActive(menu.tabName);
                                setOffset(i + 5);
                              }}
                            >
                              {" "}
                              {menu.content && (
                                <span className="pad_content">
                                  {menu.content}
                                </span>
                              )}
                              {menu.tabFor1 ? (
                                <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                              ) : null}
                            </span>
                            <span className="space_left last_child"> , </span>
                          </li>
                        ))}{" "}
                        and much more.
                      </p>
                      <p className="mobile_tab_list">
                      Add workflow automation to your chatbot instantly to streamline and automate workflows such as{" "}
                        {buttonMenu.slice(0, 5).map((menu, i) => (
                          <li className="button_list_li">
                            <AnchorLink
                              href={"#" + menu.tabFor1}
                              offset={() => -400}
                            >
                              <span
                                className={isActive === menu.tabName ? "" : ""}
                                onClick={() => {
                                  setIsActive(menu.tabName);
                                  setOffset(i);
                                }}
                              >
                                {" "}
                                {menu.content && (
                                  <span className="pad_content">
                                    {menu.content}
                                  </span>
                                )}
                                {menu.tabFor1 ? (
                                  <Tab tabFor={menu.tabFor1}>
                                    {menu.tabName}
                                  </Tab>
                                ) : null}
                              </span>
                              <span className="space_left"> , </span>
                            </AnchorLink>
                          </li>
                        ))}{" "}
                        and employee processes like{" "}
                        {buttonMenu.slice(5, 7).map((menu, i) => (
                          <li className="button_list_li">
                            <AnchorLink
                              href={"#" + menu.tabFor1}
                              offset={() => -400}
                            >
                              <span
                                className={isActive === menu.tabName ? "" : ""}
                                onClick={() => {
                                  setIsActive(menu.tabName);
                                  setOffset(i + 5);
                                }}
                              >
                                {" "}
                                {menu.content && (
                                  <span className="pad_content">
                                    {menu.content}
                                  </span>
                                )}
                                {menu.tabFor1 ? (
                                  <Tab tabFor={menu.tabFor1}>
                                    {menu.tabName}
                                  </Tab>
                                ) : null}
                              </span>
                              <span className="space_left last_child"> , </span>
                            </AnchorLink>
                          </li>
                        ))}{" "}
                        and much more.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </TabProvider>

            {featureContent.map((feature, index) => {
              if (index % 2 !== 0) {
                return (
                    <LeftImageWithContent
                    image={feature.image}
                    bgColor={feature.bgColor}
                    iD={feature.iD}
                    altImage={feature.alt}
                  >
                    <LeftImageWithContent.MainHeader>
                      {feature.header}
                    </LeftImageWithContent.MainHeader>
                    <LeftImageWithContent.SubHeader>
                      <p dangerouslySetInnerHTML={{ __html: feature.content }} />
                      {feature.content1 && (
                        <p
                          className="second_p"
                          dangerouslySetInnerHTML={{ __html: feature.content1 }}
                        />
                      )}
                   
                    </LeftImageWithContent.SubHeader>
                    <LeftImageWithContent.Link>
                      <div className="card_link_landing">
                        {feature.link && (
                          <a href={feature.link} className="url_manipulation">
                            Learn more{" "}
                            <span className="arrow_svg_link">
                              <ArrowRightICon />
                            </span>
                          </a>
                        )}
                        {feature.toDisplaySlackIcons != undefined && (
                          <SlackAndTeams />
                        )}
                      </div>
                    </LeftImageWithContent.Link>
                  </LeftImageWithContent>  
                    );
              }
              return (
                <RightImageWithContentFeature
                image={feature.image}
                bgColor={feature.bgColor}
                iD={feature.iD}
                altImage={feature.alt}
              >
                <RightImageWithContentFeature.Header>
                  <h3>{feature.header}</h3>
                </RightImageWithContentFeature.Header>
                <RightImageWithContentFeature.SubHeader>
                  <p
                    dangerouslySetInnerHTML={{ __html: feature.content }}
                  />
                {feature.content1 && (
                        <p
                          className="second_p"
                          dangerouslySetInnerHTML={{ __html: feature.content1 }}
                        />
                      )}
                
                </RightImageWithContentFeature.SubHeader>
                <RightImageWithContentFeature.Link>
                  {feature.link && (
                    <div className="card_link_landing">
                      <a href={feature.url} className="url_manipulation">
                        {feature.link}
                        <span className="arrow_svg_link">
                          <ArrowRightICon />
                        </span>
                      </a>
                    </div>
                  )}
                  {feature.toDisplaySlackIcons != undefined && (
                    <SlackAndTeams />
                  )}
                </RightImageWithContentFeature.Link>
              </RightImageWithContentFeature>
       
                     );
            })}
            <AppsIntegrations />
            <section className="center_image_home cards_landing_page">
              <div className="container">
                <div className="col-md-12">
                  <div className="row">
                    <div className="main-slider-left">
                      <h3>Your data is yours and you control it</h3>
                      <p>
                      Workativ has implemented robust security processes and controls that are in compliance with industry-leading standards and regulations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Cards cards={cardData} />
            </section>
            <div className="automate_homepage">
          <RequestForm product="automate" content="We need your feedback, inputs, and experience to help us build a great product!" AutomateCTA automateContent autoamteButton automateName isFooterForm={true} />
          </div>
          </Layout>
        </div>
        </Container>
      </section>
    </>
  );
}

function AppsIntegrations() {
  return (
    <section className="apps_landing_page" id={"integrations"}>
      <div className="container">
        <div className="apps_landing_header_wrapper">
          <div className="apps_landing_header">
            <h3>
            Connect your chatbot with 50+ apps, 400+ actions, and 20000+ workflow automations instantly. No coding
            </h3>
          </div>
          <div className="apps_landing_cards_section">
            {myObj.map((apps) => (
              <div className="apps_landing_cards col-lg-3 col-md-6 col-12">
                <h5>{apps.header}</h5>
                <div className="apps_landing_cards_wrapper">
                  <ul>
                    {apps.userApps.map((apps) => (
                      <li>
                        <img src={apps.icon} alt={apps.appName} />
                        <span>{apps.appName}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <p className="landing_content_p">
          Also available Document Management, Expense Management, Email Management, SMS Management, Notifications, and Collaboration Apps.
          </p>
        </div>
      </div>
    </section>
  );
}

const myObj = [
  {
    header: "19+ ITSM Apps",
    userApps: [
      {
        icon: servicenow,
        appName: "ServiceNow",
      },
      {
        icon: freshservice,
        appName: "Freshservice",
      },
      {
        icon: jira,
        appName: "Jira Service Desk",
      },
      {
        icon: solarwinds,
        appName: "SolarWinds",
      },
      {
        icon: service_desk,
        appName: "ServiceDesk Plus",
      },
    ],
  },
  {
    header: "15+ Access Management Apps",
    userApps: [
      {
        icon: okta,
        appName: "Okta",
      },
      {
        icon: ad,
        appName: "Microsoft Azure AD",
      },
      {
        icon: jumpcloud,
        appName: "Jumpcloud",
      },
      {
        icon: office365,
        appName: "Microsoft Office 365",
      },
      {
        icon: onelogin,
        appName: "Onelogin",
      },
    ],
  },
  {
    header: "13+ HR Apps",
    userApps: [
      {
        icon: bamboo,
        appName: "BambooHR",
      },
      {
        icon: orangehrm,
        appName: "OrangeHRM",
      },
      {
        icon: zoho_people,
        appName: "Zoho People",
      },
      {
        icon: gusto,
        appName: "Gusto",
      },
      {
        icon: sap,
        appName: "SAP SuccessFactors",
      },
    ],
  },
  {
    header: "09+ MDM Apps",
    userApps: [
      {
        icon: intune,
        appName: "Microsoft Intune",
      },
      {
        icon: mobileiron,
        appName: "MobileIron",
      },
      {
        icon: hexnode,
        appName: "Hexnode",
      },
      {
        icon: citrix,
        appName: "Citrix",
      },
      {
        icon: service_desk,
        appName: "ManageEngine MDM",
      },
    ],
  },
];

const access_management = require("../../assets/images/landing_page/access_management.png");
const asset_requests = require("../../assets/images/landing_page/asset_requests.png");
const offboarding = require("../../assets/images/landing_page/offboarding.png");
const onboarding = require("../../assets/images/landing_page/onboarding.png");
const password_resets = require("../../assets/images/landing_page/password_resets.png");
const unlock_account = require("../../assets/images/landing_page/unlock_account.png");
const user_provisioning = require("../../assets/images/landing_page/user_provisioning.png");

const buttonMenu = [
  {
    tabFor1: "vertical-tab-one",
    tabName: "unlock account",
  },
  {
    tabFor1: "vertical-tab-two",
    tabName: "password resets",
  },
  {
    tabFor1: "vertical-tab-three",
    tabName: "user provisioning",
  },
  {
    tabFor1: "vertical-tab-four",
    tabName: "access management",
  },
  {
    tabFor1: "vertical-tab-five",
    tabName: "asset requests",
  },
  {
    tabFor1: "vertical-tab-six",
    tabName: " onboarding",
  },
  {
    tabFor1: "vertical-tab-seven",
    tabName: "offboarding",
  },
];
